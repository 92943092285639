<template>
  <div class="put-container">
    <div style="padding: 0px 100px;box-sizing: border-box">
      <div class="flex-row" style="margin-top: 30px;align-items: center">
        <img src="../assets/go_home.png" style="width: 44px;height: 44px;cursor: pointer" @click="goHome">
        <p class="put-question" style="flex: 1">Ask A Question</p>
        <p class="btn-send-question" @click="showMessageDialog=true">New Question</p>
      </div>

      <div style="margin-top: 40px;width: 100%;padding-left:84px;box-sizing: border-box">
        <div v-for="(message,index) in dataList" class="question-item" :key="index">
          <div class="flex-row" style="align-items: center">
            <p class="item-title" :style="{color:message.show_type == 'reply'?'#FFA900':'#092E7F'}">
              {{ message.msg_title}}</p>
            <p class="item-time">{{ message.msg_show_time }}</p>
          </div>
          <div class="item-content" style="margin-top: 20px">
            <p v-if="message.show_type == 'send'">{{ message.msg_question }}</p>
            <p v-else>{{ message.msg_reply_content }}</p>
          </div>
          <div v-if="message.show_type == 'reply'" class="item-content-1" style="margin-top: 15px">
            <p>{{ message.msg_question }}</p>
          </div>

          <div style="width: 100%;height: 0px;border: 1px solid rgba(112, 112, 112, 0.3);margin-top: 40px">

          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showMessageDialog" :modal="true" :show-close="false"
               :modal-append-to-body="true"
               custom-class="showCustomDialogStyle">
      <div class="dialog-container">
        <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;position: relative">
          <p style="margin: 0;color: #333333;font-size: 24px;font-weight: bold;">New Question</p>
          <div style="position: absolute;right: 32px">
            <img src="../assets/icon_close.png" style="width: 24px;height: auto ;cursor: pointer"
                 @click="showMessageDialog=false">
          </div>
        </div>
        <div style=";width: 100%;padding: 0px 60px;margin-top: 40px;box-sizing: border-box;text-align: center">
          <el-input type="textarea" :rows="6" v-model="message_content"></el-input>
          <p class="btn-confirm-send" @click="submitData">Submit</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '../assets/common/common.css'
import '../assets/common/font.css'
import {getUserId} from "../utils/store"
import {reportQuestion, getReportList} from '../api/fbla'
import {formatDate} from "../utils/date";

export default {
  name: "PutQuestion",
  data() {
    return {
      message_content: '',
      user_id: getUserId(),
      showMessageDialog: false,
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      this.dataList = []
      getReportList(this.user_id).then((res) => {
        let result = res.data.data
        for (let i = 0; i < result.length; i++) {
          let message = result[i]

          let msg_title = 'Question'
          let msg_show_time = message.create_time
          let msg_question = message.message_content
          let msg_reply_content = ''
          let show_type = 'send'
          if (message.reply_info) {
            msg_title = "Reply"
            msg_show_time = message.reply_info.create_time
            msg_reply_content = message.reply_info.message_content
            show_type = 'reply'
          }
          msg_show_time = formatDate(new Date(msg_show_time * 1000), 'yyyy.MM.dd')
          let temp = {
            msg_title: msg_title,
            msg_show_time: msg_show_time,
            msg_question: msg_question,
            msg_reply_content: msg_reply_content,
            show_type: show_type
          }
          this.dataList.push(temp)
        }
      })
    },

    submitData() {
      if (this.message_content == '') {
        this.$message.warning('Please Input content')
        return
      }

      reportQuestion(this.user_id, this.message_content).then((res) => {
        this.$message.success('Report Success')
        this.showMessageDialog = false
        this.fetchData()
      })
    },

    goHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.showCustomDialogStyle {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style scoped lang="less">

::-webkit-scrollbar {
  display: none;
}


.question-item {
  margin-bottom: 40px;
}

.item-title {
  flex: 1;
  font-size: 24px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
}

.item-time {
  font-size: 24px;
  font-family: AlibabaPuHuiTi-2-55-Regular;
  font-weight: 400;
  color: #949494;
}

.item-content {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
}

.item-content-1 {
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
  background: #F2F6FF;
  border-radius: 15px 15px 15px 15px;
  font-size: 24px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  color: #666666;
  line-height: 36px;
}

.btn-confirm-send {
  cursor: pointer;
  margin: 50px auto 0px;
  width: 70%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #092E7F;
  border-radius: 32px 32px 32px 32px;
  font-size: 24px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: bold;
  color: #FFFFFF;
}

.dialog-container {
  margin: 0 auto;
  text-align: center;
  width: 35%;
  max-width: 720px;
  padding: 30px 0px;
  box-sizing: border-box;
  background-color: white;
}

.put-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.put-question {
  margin-left: 40px;
  font-size: 28px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F
}

.put-title {
  width: 120px;
  text-align: right;
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
}

.submitQuestion {
  cursor: pointer;
  margin-left: 120px;
  margin-top: 50px;
  width: 172px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background: #092E7F;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FFFFFF;
}

.btn-send-question {
  cursor: pointer;
  width: 180px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background: #092E7F;
  border-radius: 22px 22px 22px 22px;
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #FFFFFF;
}

</style>
